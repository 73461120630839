
class Utils {
  static greeting() {
    let hour = new Date().getHours();

    if (hour >= 18) {
       $('.greet').text("evening,")
    }
    else if (hour >= 12) {
       $('.greet').text("afternoon,")
    }
    else {
       $('.greet').text("morning,")
    }
  };

   static topMenuListener = () => {
    $('.top-menu-avatar > .circle-avatar').off('click').on('click', function (e) {
      if ( $('.hidden-menu-button').css('visibility') === 'hidden' )
        $('.hidden-menu-button').css('visibility','visible');
      else
        $('.hidden-menu-button').css('visibility','hidden');
    });
  };

  static showHelp() {
    (function () {
       $(function () {
           $('body').chardinJs();
           $('a[data-toggle="chardinjs"]').on('click', function (e) {
               e.preventDefault();
               return ($('body').data('chardinJs')).toggle();
           });
           return $('body').on('chardinJs:stop', function () {
           });
       });
    }).call(this);
  };

  static showValidateMassage() {
    $("form.edit_survey_answer").submit(function (event) {
       if ($("#survey_answer_z").val().length > 10) {
           return;
       }
       $(".validate").html("Tell us more! This is your chance to help your team continue to get better.<br/>Remember, it's completely anonymous, so go for it!").show().fadeOut(10000).css({
           padding: '5px 15px',
           width: '70%',
           textAlign: 'center'
       });
       if (event.preventDefault) event.preventDefault(); else event.returnValue = false;
    });
  };

  static qToolTip() {
    $('[title!=""]').qtip({
       style: {
           classes: 'qtip-bootstrap'
       },
       position: {
           my: 'top left',
           at: 'bottom center',
           viewport: $(window),
           adjust: {method: 'flip'}
       },
       show: {delay: 800}
    });
  };

  static spinnerPageLoad() {
    window.onload = function () {
       $("#quest_spinner").hide();
    };
  };

  static spinnerButtonLoad(button) {
    $(button).click(function(){
      $('#quest_spinner').show();
      $.ajax({
        success: function () {
          $('#quest_spinner').hide();
        }
      });
    });
  };

  static loginButtonChange() {
    $('#login_button').on('click', function (e) {
       $('#login_button').text('We are logging you In...');
       disableBeforeUnload();
    });


    function enableBeforeUnload() {
       window.onbeforeunload =  function () {
           $('#login_button').val();
       };
    }

    function disableBeforeUnload() {
       window.onbeforeunload = null;
    }

    enableBeforeUnload();
  };

  static surveyParticipantsListHandler() {
    $('.survey-participants-toggle').on('click', function () {
      $('.survey-participants-box').toggle();
      $('.expand-invitees-icon').toggleClass('fa-minus').toggleClass('fa-plus');
    });
  }

  static isMobile(){
    let isMoblie = navigator.userAgent.match(/(iPad|iPhone|iPod|Android|BlackBerry|Opera Mini|IEMobile)/g);
    return (isMoblie) ? true : false;
  }
}

export default Utils;